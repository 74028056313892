#report-form {

  .form-content {
    width: 694px;
  }

  .multiselect-checkboxes, .report-format, .report-content {
    display: flex;
    align-items: center;
    label {
      margin-right: ($grid-size * 5);
    }

    .message {
      margin-right: ($grid-size * 2);
    }
  }

  .report-category-options {
    display: flex;
    .ember-radio-button {
      width: 158px;
      min-width: 158px;
      margin-right: 10px;
    }
  }

  .report-content {
    margin-bottom: ($grid-size);
  }
  .divider {
    border-bottom: 1px solid $light-grey;
    margin: ($grid-size * 2) ($grid-size / 2);
  }

  .buttontmpgreen {
      -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    background-color:#95c11f;
    padding: 7px 16px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border: none; }

}
