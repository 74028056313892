.form {
  .autocomplete-input {
    .input {
      border: none;
      box-shadow: none;
      padding: 0;
      height: auto;
    }
    .autocomplete-result-item {
      background-color: $white;
      &.highlight {
        background-color: $blue;
        color: $white;
      }
    }
  }

  .autocomplete-results {
    position: absolute;
    margin: 8px -11px;
    padding: 0px;
    width: 500px;
    z-index: 10;
    background: $white;
    list-style-type: none;
    border: 1px solid $light-grey;
    border-radius: 3px;

    .autocomplete-result-item {
      box-shadow: $table-row-box-shadow;
      cursor: pointer;
      line-height: 30px;
      height: 50px;
      padding: $grid-size;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        background-color: $row-hover-blue;
      }
    }
  }
}
