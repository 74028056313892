.user-panel {
  width: 530px;
  box-shadow: $table-box-shadow;

  .user-panel-header {
    padding: 0 $grid-size;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .edit {
      font-size: $font-size-label;
      color: $blue;
      cursor: pointer;
    }
  }

  .form-content {
    border: none;

    .grant-purchasing-rights {
      display: flex;
      margin: $grid-size 0;
      .grant-purchasing-rights-label {
        color: $blue;
        font-size: $font-size-label;
        display: flex;
        align-items: center;
      }
      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label::before {
        content: url(../../icons/checkbox-unchecked.svg);
        margin-top: 2px;
        margin-right: $grid-size;
        cursor: pointer;
      }

      input[type="checkbox"]:checked + label::before {
        content: url(../../icons/checkbox-checked.svg);
      }
    }
  }

  .user-detail-actions {
    padding: $grid-size;
    border-top: 1px solid $light-grey;

    .edit-actions {
      text-align: center;

      &:first-of-type {
        margin-bottom: ($grid-size / 2);
      }
    }
  }

  .user-panel-actions {
    padding: $grid-size;
    border-top: 1px solid $light-grey;

    &.delete-user {
      display: flex;
      justify-content: flex-end;

      .button {
        padding-right: 0;
      }
    }
  }
}
