.metric-chart {
  width: 380px;
  height: 200px;
  box-shadow: $table-box-shadow;
  padding: ($grid-size * 2);

  .metric-label, .metric-value {
    &.green {
      color: $green;
    }

    &.red {
      color: $red;
    }

    &.blue {
      color: $blue;
    }
  }

  .chart {
    margin-top: $grid-size;
    display: flex;
    justify-content: center;
    position: relative;

    canvas {
      height: 120px !important;
      width: 120px !important;
    }
  }

  .metric-value {
    font-size: 32px;
    width: 120px;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -60px;
    top: 40px;
  }
}
