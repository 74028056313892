$row-width: 550px;

#site-details {
  display: flex;
  flex-direction: row;
}

#details {
  width: $row-width;

  .dropdown-menu {
    display: flex;
    justify-content: flex-end;
    padding: 0 $grid-size $grid-size 0;
    position: relative;

    .dropdown-menu-list {
      top: 45px;
    }
  }

  .autocomplete-results {
    width: 100%;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2*$grid-size;

    .button {

      &.space-right{
        margin-left: 15px;
      }
    }
  }
}


#site-drawings {
  width: 50%;
  height: fit-content;
  padding: 16px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 3px;

  .no-drawings {
    text-align: center;
  }

  .has-drawings {
    // margin-left: 20px;;
  }

  .edit-drawings {
    margin: 40px 0;
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    .space-right {
      margin-left: 15px;
    }
  }

  .drawing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;

    span {
      max-width: 300px;
      word-break: break-word;
      text-align: center;
    }
  }

  .site-drawing {
    height: 180px;
    object-fit: contain;
  }

  .site-drawing-pdf {
    height: 80px;
  }
}
