#group-table {
  .row {
    .icon-groups-blue {
      display: none
    }

    .icon-groups {
      margin: 0 3px;
      flex-shrink: 0;
    }

    &:hover {
      .icon-groups-grey {
        display: none;
      }
      .icon-groups-blue {
        display: inline-block;
      }
    }

    .table-row {
      padding: 0px;
    }
  }

  .column {
    display: flex;
    align-items: center;
    &.name {
      padding: 0 $grid-size;
      width: 35%;
    }
    &.fittings {
      width: 20%;
    }
    &.faults {
      width: 20%;
    }
    &.created-at {
      width: 20%;
    }
    &.row-indicator {
      justify-content: flex-end;
      width: 5%;
    }
  }

  tbody {
    tr .column.name {
      padding-left: 0;
    }
  }

  .cancel {
    min-width: 140px;
  }
}
