#reset-password {
  .content {
    max-width: 382px;

    .auth-submit {
      width: 100%;
    }

    #success {
      text-align: center;

      .success-heading {
        display: flex;
        align-items: center;
        justify-content: center;

        .success-tick {
          max-height: 32px;
          margin-right: $grid-size;
        }
      }
      .success-message {
        margin: ($grid-size * 3) auto ($grid-size * 2);
      }
      .subheading {
        margin-bottom: $grid-size * 5;
      }
    }
  }
}
