.terms-formatting {
  .term {
    margin-bottom: 30px;

    td.letter-order, td.numeral-order, td.capital-letter-order, td.sub-heading-order {
      display: flex;
      padding-right: 10px;
    }

    tr.sub-heading-row {
      td {
        padding-top: 15px;
      }
    }

    td.sub-heading-order {
      font-weight: $font-weight-medium;
    }

    .numerals-table {
      margin: $grid-size 0;
    }
  }

  table {
    thead {
      text-align: left;
    }
    td {
      padding-top:8px;
    }
  }
}
