$animation-time: 500ms;

.auth-form.form {
  position: relative;
  background-color: transparent;

  .row {
    margin-bottom: 25px;
    padding-left: 0;
    padding-right: 0;

    .label {
      pointer-events: none;
      display: block;
      text-align: left;
      height: 18px;
      color: $white;
      font-weight: $font-weight-regular;
      font-size: $font-size-body;
    }

    .input {
      border: none;
      box-shadow: none;
      padding: 0;
      border-radius: 0;

      input, select {
        padding: 5px 0px;
        color: $white;
      }

      &.validation-error {
        border: none;
      }

      &.focused-border {
        box-shadow: none;
      }
    }

    .input-error {
      position: relative;
      .error {
        position: absolute;
        font-size: $font-size-body;
        .alert-icon {
          display: none;
        }
      }
    }

    &.split {
      display: flex;
      justify-content: space-between;
    }

    .half {
      flex-basis: 48%;
    }

    &.row-animated {
      .input {
        // Stops a text rendering bug during the label-animated transition
        -webkit-transform: translateZ(0px);
        input,
        textarea {
          background: linear-gradient(90deg, #004A99 0%, #003E80 100%) no-repeat;
          background-image: linear-gradient(to bottom, $white, $white), linear-gradient(to bottom, silver, silver);
          background-size: 0 2px, 100% 1px;
          background-position: 0% 100%, 0% 100%;
          transition: background-size $animation-time cubic-bezier(0.64, 0.09, 0.08, 1);

          &:focus {
            background-image: linear-gradient(to bottom, $white, $white), linear-gradient(to bottom, $white, $white);
          }

          &.error {
            background-image: linear-gradient(to bottom, $error-red, $error-red), linear-gradient(to bottom, $white, $white);
            background-size: 100% 2px, 100% 1px;
          }

          &.valid {
            background-image: linear-gradient(to bottom, $green, $green), linear-gradient(to bottom, $white, $white);
            background-size: 100% 2px, 100% 1px;
          }
        }

        select {
          background: linear-gradient(90deg, #004A99 0%, #003E80 100%) no-repeat;
          background-image: linear-gradient(to bottom, $white, $white), linear-gradient(to bottom, silver, silver), url("../../icons/arrow-down-white.svg");
          background-size: 0 2px, 100% 1px, 10px;
          background-position: 0% 100%, 0% 100%, right 10px center;
          transition: background-size $animation-time cubic-bezier(0.64, 0.09, 0.08, 1);
          border-radius: 0;

          &:focus {
            background-image: linear-gradient(to bottom, $white, $white), linear-gradient(to bottom, $white, $white), url("../../icons/arrow-down-white.svg");
          }

          &.error {
            background-image: linear-gradient(to bottom, $error-red, $error-red), linear-gradient(to bottom, $white, $white), url("../../icons/arrow-down-white.svg");
            background-size: 100% 2px, 100% 1px, 10px;
          }

          &.valid {
            background-image: linear-gradient(to bottom, $green, $green), linear-gradient(to bottom, $white, $white), url("../../icons/arrow-down-white.svg");
            background-size: 100% 2px, 100% 1px, 10px;
          }
        }

        .label-animated {
          transform: translate3d(5px, 20px, 0) scale(1);
          transform-origin: top left;
          transition: $animation-time;
        }
        &.focused .label-animated, &.focus .label-animated {
          transform: scale(0.75);
          opacity: 0.5;
        }
      }
    }
    &.business-confirmation {
      .business-confirmation-label {
        font-size: $font-size-label;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label::before {
        content: url(../../icons/checkbox-unchecked-white.svg);
        margin-right: $grid-size;
      }

      input[type="checkbox"]:checked + label::before {
        content: url(../../icons/checkbox-checked.svg);
      }
    }
  }
}
