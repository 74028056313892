.ember-power-calendar {

  .ember-power-calendar-nav {
    height: 40px;
    flex-flow: column;

    .control-row {
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .ember-power-calendar-nav-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ($grid-size * 1.2);

      &.toggle-months {
        cursor: pointer;
      }
    }

    .nav-control {
      border: none;
      outline: none;
      cursor: pointer;
    }

  }

  .ember-power-calendar-days, .ember-power-calendar-months {
    border-top: 1px solid $light-grey;

    .ember-power-calendar-day-grid {

      &.months {
        padding: ($grid-size * 2);

        .ember-power-calendar-week {
          height: 60px;
        }
      }

      .ember-power-calendar-day, .ember-power-calendar-month {
        color: $black;
        font-size: $font-size-form-label;
        font-family: 'Rubik', sans-serif;
        background-color: $white;

        &.ember-power-calendar-day--selected{
          color: $white;
          background-color: $green;
          border-radius: ($grid-size * 2);
        }

        &.ember-power-calendar-day--focused {
          box-shadow: none;
        }
      }

      .ember-power-calendar-month {
        width: 60px;
        height: 60px;
      }
    }

  }

}
