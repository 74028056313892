#signup {
  padding: ($grid-size * 7.5) 0;
  justify-content: flex-start;
  height: auto;

  .back-bar {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding: $grid-size;
    margin-bottom: -40px;
    z-index: 2;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-back {
        margin-right: 8px;
      }
    }
  }

  .auth-submit {
    min-width: 223px;
  }

  .form-heading {
    margin-bottom: 40px;
  }

  .user-signup-link {
    display: block;
    max-width: 230px;
    margin: 50px auto 0;
    cursor: pointer;
  }
}
