#fitting-table, .fitting-table {
  margin-bottom: 50px;

  thead {
    tr {
      cursor: default;
    }
  }
  .fitting-table-head {
    width: 40%;
    display: flex;
    align-items: center;
    height: 50px;
    > .inner {
      justify-content: space-between;
      .back {
        display: flex;
        align-items: center;
      }
    }
  }

  .select-all-row {
    .select-all-column {
      &.replace-all-fittings {
        min-width: 120px;
      }
      &.replace-all-components {
        min-width: 250px;

        &.hide {
          visibility: hidden;
        }
      }
      &.replace-all-decals {
        min-width: 110px;
      }
    }

  }

  tbody {
    tr.order-replacement-row {
      &:hover {
        background: none;
        cursor: default;
        color: $brown;
        font-weight: $font-weight-regular;
      }
    }
  }

  .column {
    &.device-reference {
      width: 40%;
    }
    &.replacement-fitting-option {
      min-width: 120px;
      justify-content: center;
    }
    &.replacement-faulty-component-option {
      min-width: 250px;
      justify-content: center;
    }
    &.replacement-decal-option {
      min-width: 110px;
      justify-content: center;
    }
    &.row-indicator {
      width: 6px;
    }
  }

  .selecting-fittings {
    padding-left: 0px;
  }

  .icon-back {
    cursor: pointer;
    padding: 17px 5px 13px 0;
  }
}
