html,
body {
  font-family: 'Rubik', sans-serif;
  font-size: $font-size-body;
  color: $black;
}

input,
select {
  font-size: $font-size-body;
}

html,
body {
  font-weight: $font-weight-regular;
}

h1,
h2,
h3,
h4 {
  font-weight: $font-weight-regular;
}

h1,
h2,
h3,
h4,
p {
  margin: $grid-size 0;
}

a {
  text-decoration: none;
  color: $black;
}

h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}

h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}

h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}

h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}

.subheading {
  font-size: $font-size-subheading;
  line-height: $line-height-subheading;
}

.footnote {
  font-size: $font-size-footnote;
  line-height: $line-height-footnote;
}

.label {
  font-size: $font-size-label;
}
