#user-signup-form {

  .sub-heading {
    display: flex;
    align-items: center;
    margin-bottom: ($grid-size * 2);

    .section-number {
      font-size: $font-size-subheading;
      line-height: 30px;
      width: 30px;
      height: 30px;
      border-radius: 15px;

      &.required {
        background-color: $green;
      }

      &.optional {
        background-color: rgba($white, 0.6);
      }

    }

    .section-label {
      margin-left: ($grid-size * 2);
    }

    .section-optional {
      margin-left: $grid-size;
      padding-top: 8px;
      opacity: 0.5;
    }

    &.business-signup {
      margin-top: ($grid-size * 5);
    }
  }

  .business-signup-instruction {
    font-size: $font-size-label;
    line-height: $line-height-h2;
    text-align: left;

    .signup-text {
      color: $green;
      font-weight: $font-weight-medium;
    }
  }

  .business-confirmation {
    .business-confirmation-label {
      font-size: $font-size-label;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label::before {
      content: url(../../icons/checkbox-unchecked-white.svg);
      margin-right: $grid-size;
    }

    input[type="checkbox"]:checked + label::before {
      content: url(../../icons/checkbox-checked.svg);
    }
  }
}
