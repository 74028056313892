.test-event-groups-form {

  .form-instruction {
    margin-bottom: ($grid-size * 2);

    .boldFont {
      font-weight: bold;
    }
  }

  .form-content {
    max-height: 200px;
    overflow-y: scroll;
    padding: 0px;

    #groups-list {
      box-shadow: none;

      tbody {
        .column {
          height: 48px;
          &.group-name {
            display: flex;
            align-items: center;
            padding: 9px 0;
            padding-left: $grid-size;

            &.selecting-groups {
              padding-left: 0px;
            }
          }
        }
        tr:hover {
          cursor: default;
          background: none;
          color: $brown;
          font-weight: $font-weight-regular;
        }
      }
    }

    &.validation-error {
      border: 1px solid $error-red;
    }
  }

  .error {
    display: flex;
    align-items: center;
    color: $error-red;
    position: relative;
    top: 5px;
    font-size: 12px;
    img {
      padding-right: 8px;
    }
  }
}
