.file-upload-progress-bar {
  display: none;
  flex-direction: column-reverse;
  height: 35px;

  position: absolute;
  top: 75px;
  left: 0;
  right: 0px;
  background-color: $green;

  &.show {
    display: flex;
  }

  svg {
    opacity: 0.4;
  }

  .sk-circle {
    margin: 0;
    position: absolute;
    width: 10px;
    height: 20px;
    right: 20px;
    top: 7px;
  }

  .progress-message {
    position: absolute;
    top: 7px;
    left: 10px;
    color: $white;
    margin: 0;

    &.success::after {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin: 0 0 -6px 8px;
      content: "";
      background: url("../../icons/success-tick-white.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }

  .cancel, .dismiss {
    color: $white;
    margin: 0;
    text-align: right;
    padding: 0 10px 5px 0;

    cursor: pointer;
  }

  .dismiss {
    padding: 0 10px 8px 0;
  }

  .progress-bar {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(to right, transparent , rgba(256,256,256, 0.7));
    height: 10px;
  }
}
