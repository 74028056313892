.dropdown-menu.shopping-cart-dropdown-menu {
  position: relative;
  display: flex;
  justify-content: flex-end;

  .dropdown-menu-list {
    width: 200px;
    top: 35px;
    right: -10px;
    z-index: 5;

    .dropdown-menu-list-item {
      letter-spacing: 1px;
      text-transform: none;
      font-weight: $font-weight-regular;
    }
  }
}
