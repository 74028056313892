.onboarding {
  min-height: calc(100vh - #{$nav-bar-height});
  display: flex;

  .pagination-control {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: $grid-size * 10;
    height: $grid-size * 52;
    padding-top: 50px;

    img {
      height: $grid-size * 6;
    }

    &.hidden {
      visibility: hidden;
    }
  }
  .swiper-container {
    margin-top: $grid-size * 9;
    height: 590px;

    .swiper-pagination-bullet {
      height: 12px;
      width: 12px;

      &.swiper-pagination-bullet-active {
        background: $blue;
      }
    }
  }

}
