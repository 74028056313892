$site-header-height: 80px;

.site-page {
  min-height: calc(100vh - #{$nav-bar-height});

  .site-header {
    display: flex;
    border-bottom: 1px solid $light-grey;

    .site-header-action {
      display: flex;
      align-items: center;
      font-size: $font-size-label;

      &.text-blue {
        color: $blue;
      }
    }

    .back-button {
      background-color: $light-grey-background;
      border-left: 1px solid $light-grey;
      border-right: 1px solid $light-grey;

      a {
        height: 84px;
        padding: 2*$grid-size;
        width: 210px;

        img {
          margin-right: 15px;
        }
      }

      a:hover {
        cursor: pointer;
      }
    }

    .site-info-header {
      display: flex;
      justify-content: space-between;
      width: calc(#{$max-width} - 200px);
      position: relative;

      .site-info {
        padding: 10px ($grid-size * 2);
        min-width: 380px;

        h4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .site-header-actions {
        display: flex;
        justify-content: flex-end;

        .site-header-action {
          padding: $grid-size ($grid-size * 0.5);
          a {
            img {
              margin-top: -5px;
            }
          }
        }
      }
    }
  }

  .body {
    display: flex;
    min-height: calc(100vh - #{$site-header-height} - #{$nav-bar-height});

    .site-menu {
      min-height: calc(100vh - #{$site-header-height} - #{$nav-bar-height});
      background-color: $light-grey-background;
      border-left: 1px solid $light-grey;
      border-right: 1px solid $light-grey;

      .menu-actions {
        display: block;
        height: 50px;
        width: 210px;
        cursor: pointer;
        padding: 17px 1.8*$grid-size;

        .order-item-count {
          margin-top: -8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          background-color: $green;
          color: $white;
          font-size: $font-size-form-label;

          &.hide {
            display: none;
          }
        }

        &.active {
          color: $green;
          border-left: 2px solid $green;
        }
        &.shopping-cart {
          display: flex;
          padding-right: 0;
        }
      }
    }

    .content {
      width: 100%;
      padding-left: 2*$grid-size;
      padding-top: 2*$grid-size;
      padding-bottom: 5*$grid-size;

      .header {
        h2 {
          margin-top: 0;
          margin-bottom: ($grid-size * 2);
        }
      }
    }
  }

}
