#nav-bar {
  > .inner {
    height: 100%;
    max-width: $max-width;
    padding: 0 ($grid-size * 2);

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  height: $nav-bar-height;
  background: $blue-gradient;

  .left {
    display: flex;
    align-items: center;
    .nav-bar-link {
      margin: 0 50px;
    }
  }

  .right {
    position: relative;
  }

  .nav-bar-item {
    flex-shrink: 1;
  }
  .nav-bar-logo {
    padding-right: 50px;
    img {
      height: 34px;
      width: 92px;
    }
  }
  .nav-bar-link {
    text-decoration: none;
    cursor: pointer;

    > a {
      color: $pale-blue;
    }
    &:hover {
      > a {
        color: $white;
      }
    }

    &.users {
      position: relative;
    }
  }
}
