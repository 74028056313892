.file-uploader {
  label {
    cursor: pointer;
  }

  img {
    margin-right: 7px;
  }

  input {
    display: none;
  }
}
