#signup-terms {
  text-align: left;
  margin-bottom: 125px;

  .heading {
    text-align: center;
    margin: -15px auto 40px;
  }

  .actions {
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 0;
    background-color: rgba($blue, 0.5);
    box-shadow: 0 0 65px 40px rgba($blue, 0.5);

    > .inner {
      max-width: 925px;
      height: 130px;
      padding: ($grid-size * 2);
      margin: auto;
      display: flex;
      justify-content: flex-end;

      .terms-submit {
        align-self: center;
        margin: 0;
      }
    }
  }
  p {
    margin-bottom: 25px;
  }
}
