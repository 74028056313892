@mixin button-hover-state($bg-colour, $hoverLighten, $activeLighten, $disabledOpacity) {
  &:disabled {
    opacity: $disabledOpacity;
    cursor: default;
  }
  &:not(:disabled) {
    &:hover {
      background-color: lighten($bg-colour, $hoverLighten);
    }
    &:active {
      background-color: lighten($bg-colour, $activeLighten);
    }
  }
}
