#shepherdOverlay {
  background-color: #00254D;
  opacity: 0.7;
}

.sequence-element {
  &.sequence-element-request-access {
    display: flex;
  }

  &.sequence-element-test-tab {
    .tab-menu-action img {
      margin-left: 5px;
    }
  }

  &.sequence-element-click-fitting-action {
    .table-row .column {
      display: flex;
      align-items: center;
    }
  }

  &.sequence-element-commissioned-filter, &.sequence-element-collected-filter {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-table;
    width: 40%;
    max-width: 220px;

    .filter-column {
      width: 100%;
    }
  }

  &.highlight {
    background: $white;
    .button {
      opacity: 1;
    }
  }
}

.shepherd-element.sequence-tooltip {
  max-width: 100%;
  max-height: 100%;

  .shepherd-content {
    header {
      height: $grid-size * 3.5;
      a.shepherd-cancel-link {
        padding: $grid-size * 1.5;
        opacity: 1;
        background-image: url("../../icons/icon-close-small.svg");
        background-repeat: no-repeat;
        background-position: center;

        width: 10px;
        height: 10px;

        color: transparent;
      }
    }
    max-width: 534px;

    border-radius: 3px;

    &, .shepherd-buttons .shepherd-button.button {
      font-size: $font-size-tooltip;
    }
    .shepherd-text {
      padding: ($grid-size * 2) ($grid-size * 2);
      p {
        margin: 0;
        line-height: 24px;
      }
    }
    .sequence-progress {
      display: flex;
      justify-content: center;
      padding: ($grid-size * 2.5) 0 0;

      .sequence-progress-item {
        width: 6px;
        height: 6px;
        border-radius: 50%;

        margin: 0 ($grid-size / 2);
        background-color: $light-blue;
        &.active {
          background-color: $blue;
        }
      }
    }


    footer {
      padding: 0 ($grid-size * 2) ($grid-size * 2);
      .shepherd-buttons {
        .shepherd-button.button {
          min-width: 140px;
          text-align: center;
          line-height: 17px;
          text-transform: none;
          padding: 6px 0;
          letter-spacing: normal;

          &.button-primary {
            background-color: $blue;
          }
          &.button-secondary {
            background-color: $light-blue;
            color: #053687;
          }
        }
      }
    }
  }
}
