#employment-table {
  margin-bottom: 50px;

  .column {
    &.user {
      width: 30%;
      &.fullName {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.userType {
      width: 20%;
    }
  }

  tbody {
    tr {
      &:hover {
        background: none;
        cursor: default;
        color: $brown;
        font-weight: $font-weight-regular;
      }

      &.userRow:hover {
        background: $light-blue;
        font-weight: $font-weight-medium;
        color: $blue;
      }

      td:last-of-type {
        padding: 0px;
      }
    }
  }

  .row-action {
    height: 50px;
    display: flex;
    justify-content: flex-end;

    .row-indicator {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }
  }

  .input {
    border: 1px solid rgba(0,0,0,0);
    background-color: $white;
    box-shadow: $form-row-box-shadow;
    border-radius: 3px;

    select {
      padding: 0 $grid-size;
      height: 36px;
      width: 100%;
      color: $black;
      background-image: url("../../images/icon-arrow-down.svg");
      background-position: right 0px top 2px;

      option {
        color: $black;
      }
    }
  }
}
