#terms {
  .heading {
    text-align: center;
    margin: -15px auto 40px;
  }

  .back-bar {
    max-width: 1200px;
    padding: ($grid-size * 3) $grid-size;
    margin: 0 auto -40px;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon-back {
        margin-right: 8px;
      }
    }
  }

  .content {
    padding: 50px 0;
    max-width: 902px;
    margin: auto;
    padding: $grid-size;

    .heading {
      margin: 30px 0;
    }
  }
}
