html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


html, body {
  margin: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

input[type="text"],
input[type="email"],
input[type="file"],
input[type="password"],
input[type="number"],
input[type='url'],
input[type='tel'],
select,
textarea {
  border: none;
  outline: none;
  border: none;
  border-radius: rem(0);
  box-shadow: none;
  background: transparent;
  background-image: none;
  padding: rem(0);
  font-family: 'Rubik', sans-serif;
}

select {
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

button,
.button {
  background: none;
  border: none;
  outline: none;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

// Remove any outline's on buttons, links from focus event
:focus {outline:none;}

// Remove IE select dropdown icon
select::-ms-expand {
  display: none;
}

// Remove IE select field background on option selection
select::-ms-value {
  background: none;
  color:#000;
}

//Remove Firefox select focus outline
select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}
