#edit {
  padding: ($grid-size * 2) ($grid-size * 2.5);

  .back-button {
    margin-bottom: ($grid-size * 2);
  }

  > .inner {
    max-width: 600px;

    .row {
      padding-left: 0;
    }

    .edit-actions {
      display: flex;
      justify-content: flex-end;
      padding: ($grid-size * 2) $grid-size;
      
      .delete-icon {
        height: 17px;
      }
    }
  }
}
