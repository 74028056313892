#flash {
  position: fixed;
  top: 50px;
  text-align: center;
  right: 0;
  left: 0;
  z-index: 100;

  .alert {
    display: inline-block;
    padding: ($grid-size * 2.5) ($grid-size * 7);
    background-color: $white;
    border-radius: 2px;
    font-size: $font-size-label;
    border: 1px solid;

    &.alert-danger {
      color: $error-red;
      border-color: $error-red;
    }

    &.alert-success {
      color: $white;
      background-color: $green;
      border-color: $green;

      &::before {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin: 0 5px -6px 0;
        content: "";
        background: url("../../icons/success-tick-white.svg") no-repeat 0 0;
        background-size: 100%;
      }
    }
  }
}
