#quick-test-modal {

  .header-label-row{
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;

    &:first-of-type {
      border-top: none;
    }
  }

  .form-content {
    padding: 0;
    border: 1px solid $light-grey;

    .row {
      padding-left: $grid-size;
      padding-right: $grid-size;
    }
  }

  .last-test-row {
    padding-bottom: $grid-size;
  }
}
