.table {
  box-shadow: $table-box-shadow;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid $light-grey;
      cursor: pointer;

      th, tr, td {
        padding: 15px $grid-size;
      }
    }

    thead {
      tr {
        cursor: default;

        th {
          font-size: $font-size-table-heading;
          font-weight: $font-weight-medium;
          line-height: $line-height-table-heading;
          letter-spacing: 1px;
          text-transform: uppercase;

          > .inner {
            display: flex;
            align-items: center;

            .sort-icon {
              cursor: pointer;
              img {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        color: $brown;
        td {
          font-size: $font-size-table;
          overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;

          .truncated {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .arrow-right-grey {
            display: block;
          }
          .arrow-right-grey-blue {
            display: none;
          }
        }

        &:hover {
          background: $light-blue;
          color: $blue;
          font-weight: $font-weight-medium;

          .arrow-right-grey {
            display: none;
          }
          .arrow-right-grey-blue {
            display: block;
          }
        }
      }
    }
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 0 $grid-size;

    .column {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-indicator {
    display: flex;
    align-items: center;
  }

  .filter-row {
    padding: 0;
    cursor: default;
    justify-content: flex-start;

    .filter-column {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-table;
      width: 40%;
      max-width: 220px;
      cursor: pointer;

      &.active {
        color: $green;
        border-bottom: 2px solid $green;
      }
    }
  }

  .select-all-row {
    .select-all-column {
      display: flex;
      align-items: center;
      justify-content: center;

      .select-all-label {
        font-size: $font-size-label;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label::before {
        content: url(../../icons/checkbox-unchecked.svg);
      }

      input[type="checkbox"]:checked + label::before {
        content: url(../../icons/checkbox-checked.svg);
      }
    }
  }

  .row-indicator {
    text-align: right;
  }

  .table-actions {
    padding: $grid-size;
    border-top: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;

      .button {
        height: 30px;

        &:not(:first-child) {
          margin-left: $grid-size;
        }
      }
    }
  }

  .empty-table {
    padding: 50px 0;
    text-align: center;

    .empty-image {
      padding-bottom: 50px;

      img {
        width: 190px;
        height: 190px;
      }
    }

    .empty-message {
      margin: 0 auto;
      width: 400px;
      color: $brown;
    }

    .empty-actions {
      display: inline-block;
      margin: 25px auto;
      padding: 7px;
    }
  }
}
