.search-bar {
  display: flex;
  align-items: center;
  height: 40px;
  width: 250px;
  border: 1px solid $light-grey;
  border-radius: 4px;
  background-color: #FFFFFF;
  padding: $grid-size;
  margin-left: 2*$grid-size;

  img {
    margin-right: $grid-size;
  }

  .search-input {
    width: 100%;
  }

  &.large {
    width: 400px;
  }
}
