#order-item-table {
  margin-bottom: 50px;

  thead {
    tr {
      cursor: default;
    }
  }

  tbody {
    tr {
      &:hover {
        background: none;
        cursor: default;
        color: $brown;
        font-weight: $font-weight-regular;
      }
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    height: 50px;
    > .inner {
      justify-content: space-between;
      .back {
        display: flex;
        align-items: center;
      }
    }
  }

  .column {
    display: flex;
    align-items: center;
    height: 48px;
  }

  .is-deleting-order-items {
    padding-left: 0px;
  }

  .icon-back {
    cursor: pointer;
    padding: 17px 5px 13px 0;
  }
}
