#site-permission-table {
  box-shadow: none;

  tr {
    border-left: none;
    border-right: none;
  }

  thead {
    tr {
      border-top: none;
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: default;
        background: none;
        color: $brown;
        font-weight: $font-weight-regular;
      }

      td:last-of-type {
        padding-right: 0px;
      }
    }
  }

  .empty-table {
    padding: ($grid-size * 3) 0;
  }

  .column {
    &.businessName {
      width: 30%;
    }
    &.address {
      width: 50%;
    }
  }

  .table-actions {
    border: none;
  }

  .row-action {
    display: flex;
    justify-content: flex-end;
  }
}
