.fitting-info-banner {
  display: flex;
  align-items: center;
  padding: ($grid-size / 2) $grid-size;

  color: $white;
  background-color: $green;

  .icon {
    margin-right: $grid-size;
  }

  .label {
    margin: 0;
  }
}
