#test-event-group-table {
  margin-bottom: 50px;

  .test-event-group-table-head {
    display: flex;
    align-items: center;
    height: 50px;
    > .inner {
      justify-content: space-between;
      .back {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;

        .icon-test-event {
          margin: 0 $grid-size 2px;
          flex-shrink: 0;
        }
      }
    }

    &:last-of-type {
      > .inner {
        justify-content: flex-end;

        .test-event-archived {
          color: $table-header-light-grey;
        }
      }
    }
  }

  tr {
    td:last-of-type {
      padding: 0px;
    }
  }

  .column {
    display: flex;
    align-items: center;
    height: 48px;

    &.name {
      max-width: 95%;
      padding: 9px 0;
      padding-left: $grid-size;
    }
  }

  .icon-back {
    cursor: pointer;
    padding: 15px 5px 15px 0;
  }
}
