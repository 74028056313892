.wholesaler-info-banner {
  display: flex;
  align-items: center;
  height: 45px;
  padding: ($grid-size / 2) $grid-size;
  margin-left: -($grid-size * 2);
  margin-top: -($grid-size * 2);
  margin-right: -($grid-size * 2);
  margin-bottom: $grid-size;

  color: $white;
  background-color: $green;

  .icon {
    margin-right: $grid-size;
  }
}
