#forgot-password {
  .content {
    max-width: 382px;
    margin: 0 auto;
    .instruction {
      margin: 0 auto 40px;
    }
    .auth-submit {
      width: 100%;
    }
    .success .confirmation {
      margin: 15px auto 40px;
    }
  }
}
