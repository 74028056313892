.fittings-index {
  display: flex;

  .fittings-list {
    flex: 1;
  }

  .fitting-details {
    margin-left: 20px;
    flex-basis: 354px;

    .fitting-panel {
      box-shadow: $table-box-shadow;

      .edit {
        font-size: $font-size-label;
        color: $blue;
        cursor: pointer;
      }

      .fitting-header {
        padding: 0 $grid-size;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit {
          font-size: $font-size-label;
          color: $blue;
          cursor: pointer;
        }
      }

      .fitting-tab-menu {
        display: flex;
        justify-content: space-around;
        height: 40px;
        border-bottom: 1px solid $light-grey;

        .tab-menu-action {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          min-width: 115px;
          border-bottom: 2px solid rgba(0,0,0,0);

          .faultyIndicator {
            margin-left: 5px;
          }

          &.active {
            color: $green;
            border-bottom: 2px solid $green;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .successConfirmation {
        display: none;
        border-radius: 0px;
        height: 50px;
        transition: display 500ms;

        .icon {
          height: 16px;
        }

        &.show {
          display: flex;
        }
      }

      .fitting-panel-actions {
        padding: $grid-size;
        border-top: 1px solid $light-grey;

        &.delete-fitting {
          display: flex;
          justify-content: flex-end;
        }
      }

      .fitting-actions {
        padding: $grid-size;
        border-top: 1px solid $light-grey;

        .delete-action {
          display: flex;
          justify-content: flex-end;
          padding-bottom: $grid-size;

          .button {
            padding-right: 0;
          }
        }

        .edit-actions {
          text-align: center;

          &:first-of-type {
            margin-bottom: ($grid-size / 2);
            width: 100%;
          }
        }
      }

      .form-content {
        border: none;
      }

      .header-label-row, .maintenance-row {
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;

        &:first-of-type {
          border-top: none;
        }
      }

      .maintenance-row {
        .header-label-row {
          display: flex;
          justify-content: space-between;
        }

        .fitting-actions {
          border-top: none;
        }
      }

      .no-maintenance-rows, .no-latest-test {
        text-align: center;
        background: $white;
        border-bottom: 1px solid $light-grey;

        .empty-image {
          img {
            height: 165px;
          }
          margin-top: ($grid-size * 5);
          margin-bottom: ($grid-size * 5)/2;
        }

        .empty-message {
          margin: 0px ($grid-size * 7) ($grid-size * 10);
          font-size: $font-size-label;
          color: $brown;

          &.long {
            margin: 0px ($grid-size * 4.5) ($grid-size * 10);
          }
        }
      }

      .see-latest-test {
        display: flex;
        justify-content: center;
        padding: $grid-size 0;
        border-bottom: 1px solid $light-grey;

        .button {
          font-size: 16px;
        }
      }

      .add-action {
        display: flex;
        justify-content: flex-start;
        padding: $grid-size 0px ($grid-size * 4);
      }

      .test-event-warning {
        display: flex;
        align-items: center;
        color: #004A99;
        font-size: 14px;
        padding: $grid-size;
        padding-bottom: 0;
        img {
          padding-right: 8px;
        }
      }

      #fitting-details-form {
        .commission-fitting {
          margin: $grid-size 0;
          .mark-commissioned-label {
            color: $blue;
            font-size: $font-size-label;
            display: flex;
            align-items: center;
          }
          input[type="checkbox"] {
            display: none;
          }

          input[type="checkbox"] + label::before {
            content: url(../../../icons/checkbox-unchecked.svg);
            margin-top: 2px;
            margin-right: $grid-size;
            cursor: pointer;
          }

          input[type="checkbox"]:checked + label::before {
            content: url(../../../icons/checkbox-checked.svg);
          }
        }
      }

      #test-and-status-form {
        .last-test-row {
          padding-bottom: $grid-size;
        }

        .confirmation-text {
          color: $blue;
        }

        .add-action {
          background: $light-grey-background;
        }

        .latest-test-header {
          padding: ($grid-size * 2) $grid-size;
          border-bottom: 1px solid $light-grey;
        }

        .test-event-warning {
          padding-bottom: $grid-size;
          border-bottom: 1px solid $light-grey;
        }

      }

      #latest-test-modal {
        .form-content {
          padding: 0;
          border: 1px solid $light-grey;

          .row {
            padding-left: $grid-size;
            padding-right: $grid-size;
          }
        }
      }

      #maintenance-form {
        .form-content {
          background: $light-grey-background;

          .header-label-row {
            border-bottom: none;
          }

          .last-maintenance-row {
            padding-bottom: $grid-size;
          }
        }
      }

    }
  }

}
