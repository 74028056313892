$animation-time: 500ms;

.form {
  .form-content {
    background-color: $white;
    border: 1px solid $light-grey;

    .form-instruction {
      padding: 25px 70px $grid-size;
      text-align: center;
    }
  }

  .header-label-row {
    background: $light-grey-background;
    height: 30px;
    padding: 6px $grid-size;

    label {
      color: $brown;
      line-height: 18px;
      font-size: $font-size-label;
    }
  }

  .row {
    padding: $grid-size $grid-size 0;

    &.white {
      .input {
        background: $white;
      }
    }

    .label {
      color: $brown;
      font-size: $font-size-form-label;
      pointer-events: none;
      display: block;
      text-align: left;
      height: 16px;
      font-weight: $font-weight-regular;
      font-size: $font-size-body;
    }

    &.split {
      display: flex;
      justify-content: space-between;
    }

    .half {
      flex-basis: 48%;
    }

    .input {
      border: 1px solid rgba(0,0,0,0);
      box-shadow: $form-row-box-shadow;
      border-radius: 3px;
      padding: 7px $grid-size;
      position: relative;

      input, select {
        width: 100%;
        color: $black;
      }

      select {
        background-image: url("../../images/icon-arrow-down.svg");
        background-position: right -10px top 0px;

        option {
          color: $black;
        }

        &:disabled {
          background-image: none;
        }
      }

      textarea {
        width: 100%;
        height: 96px;
        resize: none;
        line-height: 24px;
        font-size: $font-size-body;
      }

      &.focused-border {
        box-shadow: $form-row-box-shadow-focused;
      }

      &.validation-error {
        border: 1px solid $error-red;
      }
    }

    .form-validated-input, .form-validated-select, .form-validated-wholesaler-select, .disabled-form-input {
      .input {
        height: 50px;

        input, select {
          padding: 0;
          height: 34px;
          margin-top: -$grid-size;
        }

        &.textarea {
          height: 132px;
        }
      }
    }

    .form-validated-select {
      .input {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .input-error {
      .error {
        display: flex;
        align-items: center;
        color: $error-red;
        position: relative;
        top: 5px;
        font-size: 12px;
        img {
          padding-right: 8px;
        }
      }
    }

    .places-search-input {
      .progress-bar {
        margin-top: -5px;

        svg {
          height: 2px;
        }
      }
    }

    &.row-animated {
      .input {

        .label-animated {
          transform: translate3d(5px, 8px, 0) scale(1);
          transform-origin: top left;
          transition: $animation-time;
        }
        &.focused .label-animated {
          transform: scale(0.75);
          font-weight: $font-weight-bold;
        }
      }

      .form-validated-select {
        .input {
          .label-animated {
            transform: translate3d(5px, 15px, 0) scale(1);
            transform-origin: top left;
            transition: $animation-time;
          }
          &.focused .label-animated {
            margin-top: 7px;
            transform: scale(0.75);
            font-weight: $font-weight-bold;
          }
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0px;
      padding-bottom: $grid-size;
    }
  }

  .form-actions {
    margin-top: ($grid-size * 2);
    display: flex;
    justify-content: flex-end;

    .button {
      margin-left: $grid-size;
    }
  }
}
