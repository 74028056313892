#shopping-cart-group-table {
  margin-bottom: 50px;

  .table-header {
    display: flex;
    align-items: center;
    height: 50px;
    > .inner {
      justify-content: space-between;
      .table-heading {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;

        .icon-shopping-cart {
          margin-right: $grid-size;
          margin-top: -2px;
        }
      }
    }
  }

  tbody {
    .table-row {
      padding-right: 0px;
    }
  }

  .column {
    display: flex;
    align-items: center;
    height: 48px;
  }

  .is-checking-out {
    padding-left: 0px;
  }
}
