.load-more-button {
  padding: 0;
  display: block;

  .items-displayed-text {
    margin: 0;
    color: $brown;
    cursor: default;
  }
  .load-more-text {
    margin-bottom: 0;

    .icon-arrow-down-blue {
      display: inline-block;
    }
  }
}
