.ember-radio-button {
  padding: ($grid-size * 1.5) $grid-size;
  border: 1px solid $light-grey;
  border-radius: 4px;
  min-width: 245px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
  text-align: center;

  input[type="radio"] {
    display: none;
  }

  &.checked {
    background-color: $light-blue;
    color: $blue;
  }

  &.round {
    padding: 0;
    border: none;
    min-width: 0;
    margin: 0;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    input[type="radio"] + label::before {
      margin-right: ($grid-size / 2);
      height: ($grid-size * 2);
      content: url(../../icons/radio-button-not-active.svg);
    }

    input[type="radio"]:checked + label::before {
      margin-right: ($grid-size / 2);
      height: ($grid-size * 2.1);
      content: url(../../icons/radio-button-active.svg);
    }
  }
}
