#support {
  .title, .back-to-sites {
    display: block;
    margin: ($grid-size * 3) 0;

    .icon {
      margin-right: $grid-size;
    }
  }
  .support-item {
    display: flex;
    align-items: center;
    margin-bottom: $grid-size * 2.5;

    a {
      color: $blue;
    }

    .icon, .icon-container {
      height: $grid-size * 2;
    }

    .icon-container {
      width: 30px;
      text-align: center;
      margin: 0 $grid-size 0 -5px;

      &.phone {
        height: $grid-size * 2.5;
      }
    }

    .icon-phone {
      height: $grid-size * 2.5;
    }
  }
}
