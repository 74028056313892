#site-request {
  background: none;
  color: $black;
  .title {
    display: flex;
    justify-content: center;
    margin-bottom: ($grid-size * 3);
  }

  .actions {
    .auth-submit {
      display: block;
      :last-of-type {
        padding-top: ($grid-size / 2);
      }
    }
  }
}
