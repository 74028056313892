.dropdown-menu {
  .dropdown-menu-trigger {
    cursor: pointer;
    color: $pale-blue;

    .dropdown-arrow-white {
      display: none;
    }
    &:hover {
      color: $white;
      .dropdown-arrow-white {
        display: inline-block;
      }
      .dropdown-arrow-pale-blue {
        display: none;
      }
    }
  }

  .dropdown-menu-list {
    position: absolute;
    width: 295px;
    top: 53px;
    right: 0;
    background-color: $white;
    border-radius: 1px;
    box-shadow: $form-row-box-shadow;

    z-index: 99;

    .dropdown-menu-list-item {
      width: 100%;
      padding: 16px $grid-size;
      box-shadow: $table-row-box-shadow;
      display: block;
      text-align: left;
      cursor: pointer;
      color: $blue;

      &:hover:not([disabled]) {
        background-color: $light-blue;
      }

      &:disabled {
        cursor: default;
        color: rgba($blue, 0.4);
      }

      &.delete {
        color: $red;
        &:disabled {
          color: rgba($red, 0.4);
        }
      }

      &:first-child {
        &::after,
        &::before {
          bottom: 100%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &::after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 11px;
          border-bottom-width: 14px;
          left: 96%;
          margin-left: -20px;
        }
        &::before {
          border-color: rgba(113, 158, 206, 0);
          border-bottom-color: rgba(0, 0, 0, 0.03);
          border-width: 13px;
          border-bottom-width: 16px;
          left: 96%;
          margin-left: -22px;
        }

        &:hover:not([disabled]) {
          &:after {
            border-bottom-color: $light-blue;
          }
        }
      }
    }
  }
}
