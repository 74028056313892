.multiselect-checkboxes {
  label {
    margin: 5px 8px -4px 8px;
    height: 30px;
    cursor: pointer;

    &.large {
      text-align: center;
      padding: ($grid-size * 1.5) $grid-size;
      border: 1px solid $light-grey;
      border-radius: 4px;
      height: auto;
      min-width: 158px;

      &.checked {
        background-color: $light-blue;
        color: $blue;
      }
    }

    &.round {
      display: flex;
      align-items: center;
      margin-right: ($grid-size * 5);

      &::before {
        margin: 0 ($grid-size / 2) -4px 0;
        content: url(../../icons/radio-button-not-active.svg);
      }

      &.checked::before {
        margin: 0 $grid-size / 2 (-$grid-size / 2) 0;
        content: url(../../icons/radio-button-active.svg);
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label::before {
    content: url(../../icons/checkbox-unchecked.svg);
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label::before {
    content: url(../../icons/checkbox-checked.svg);
  }
}
