#change-password {
  padding-top: 130px;
  text-align: center;

  .change-password-success {
    text-align: center;
    .success-text {
      margin: ($grid-size * 3) auto 15px;
    }
    .button {
      margin: ($grid-size * 3) auto;
    }
  }
}
