#test-event-modal {

  .modal-content.test-event-details {
    width: 700px;

    #test-event-form {
      .date-picker-label {
        margin-bottom: $grid-size;
      }
    }
  }

}
