.site-permissions-panel {
  margin-left: 50px;
  box-shadow: $table-box-shadow;

  .site-permissions-panel-header {
    padding: 0 $grid-size;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
