.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  padding: 7px 16px;
  border-radius: 3px;
  font-size: $font-size-label;
  outline: none;
  cursor: pointer;
  border: none;

  &.text-blue {
    color: $blue;
  }

  &.text-red {
    color: $red;
  }

  &.text-green {
    color: $green;
  }

  &.text-brown {
    color: $brown;
  }

  &.green {
    background-color: $green;
    @include button-hover-state($green, 3%, 7%, 0.5);
  }

  &.red {
    background-color: $red;
    @include button-hover-state($red, 3%, 7%, 0.3);
  }

  &.blue {
    background-color: $blue;
    @include button-hover-state($blue, 5%, 9%, 0.3);
  }

  &.light-blue {
    background-color: $light-blue;
  }

  &.white {
    background-color: $white;
    color: $blue;

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.red {
      color: $red;
    }
  }

  &.large {
    font-size: 18px;
    padding: 16px 60px;
  }

  &.small {
    padding: 7px $grid-size;
  }

  &.standard {
    min-width: 120px;
  }

  &.border-grey {
    border: 1px solid rgba(0,0,0,0.07);
  }

  .icon {
    margin-right: $grid-size;
  }

  // Fixes a safari bug where button text wont center because of display: flex
  &::before,
  &::after {
    content: '';
    flex: 1 0 auto;
  }

}
