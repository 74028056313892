.modal-overlay {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 10;
  background-color: rgba(#00254D, 0.7);

  .container {
    position: relative;
    margin: auto;
    background-color: $light-blue;
    padding: ($grid-size * 4.5) ($grid-size * 4) ($grid-size * 2);
    box-shadow: $table-box-shadow;

    .modal-header {
      margin-bottom: 35px;

      .form-heading {
        display: inline;
      }

      .modal-close {
        position: absolute;
        cursor: pointer;
        right: 25px;
      }
    }

    .modal-content {
      width: 520px;
      > .inner {
        padding: ($grid-size * 2);
        background-color: $white;
        text-align: center;
        box-shadow: $table-box-shadow;

        .message {
          text-align: left;
          margin-bottom: ($grid-size * 2);
          font-size: $font-size-body;

          &.error-message {
            color: $red;
          }
        }
      }
      &.wide-content {
        max-height: 70vh;
        width: auto;
        overflow-y: scroll;
      }
      .form-content {
        padding: $grid-size;
      }
      .row {
        padding-left: 0;
        padding-right: 0;
      }
      .modal-actions {
        margin-top: ($grid-size * 2);
        display: flex;
        justify-content: flex-end;

        .button {
          margin-left: $grid-size;
        }
      }
    }

    .preview {
      width: 100%;
      height: 77vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .react-pdf__Document {
        width: 100%;
        overflow-y: scroll;
        text-align: center;

        p {
          margin: 20px 0;
        }

        .react-pdf__Page__canvas {
          width: 100% !important;
        }

        .react-pdf__Page {
          width: 99.5%;
          box-shadow: 1px 1px 5px lightgrey;
        }
      }
    }
  }

  &.white {
    background-color: rgba($white, 0.55);
  }
}
