.screen-too-small {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;

  overflow: none;
  text-align: center;

  .message {
    max-width: 375px;
    margin: ($grid-size * 4) auto $grid-size;
  }
}
