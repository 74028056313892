.replacement-option-button {
  margin: 0 ($grid-size / 2);

  &.hide-option {
    display: none;
  }
  .button {
    max-width: 98px;
    font-size: $font-size-small;
    padding: 7px ($grid-size / 2);

    .icon {
      margin-top: 2px;
      margin-right: ($grid-size / 2);
      height: $grid-size;
    }
  }
}
