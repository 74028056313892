#site-request-table {
  margin-bottom: 50px;

  .row-action {
    padding-right: 0px;
    text-align: right;

    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: ($grid-size * 3);

      .divider {
        margin: 0 ($grid-size * 2);
        height: 12px;
        width: 2px;
        background: $light-grey;
      }
    }

  }

  tbody {
    tr:hover {
      cursor: default;
      background: none;
      color: $brown;
      font-weight: $font-weight-regular;
    }
  }

  .column {
    &.first-name {
      width: 15%;
    }

    &.last-name {
      width: 15%;
    }

    &.business-name {
      width: 20%;
    }

    &.site-address {
      width: 30%;
    }
  }
}
