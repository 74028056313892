#site-table {
  background-color: $white;
  margin-bottom: 50px;

  tr {
    &.not-permitted {
      background-color: $light-grey-background;

      &:hover {
        color: $brown;
        font-weight: $font-weight-regular;
        cursor: default;
        .request-access:hover {
          cursor: pointer;
          font-weight: $font-weight-regular;
        }
      }
    }

    td:last-of-type {
      padding: 0px;
    }
  }

  .row-action {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    text-align: left;

    .pending-access {
      cursor: default;
    }

    .row-indicator {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
    }
  }

  .column {
    &.unit {
      width: 12%
    }
    &.unittext {
      max-width: 5%;
      overflow: hidden;
    }
    &.address {
      padding-left: 2%;
      width: 23%;
    }
    &.business-name {
      width: 25%;
    }
    &.building-name {
      width: 17%;
    }
    &.next-test {
      width: 13%;
    }
    &.created-at {
      width: 17%;
    }
    &.test-activated {
      width: 15%;
      text-align: center;

      span {
        vertical-align: -webkit-baseline-middle;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
        margin-right: 8px;
        margin-left: -30px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background-color: #95c11f;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #95c11f;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}
