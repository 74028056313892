.fitting-form {
  .test-event-warning {
    display: flex;
    align-items: center;
    color: #004A99;
    font-size: 14px;
    padding: $grid-size;
    padding-bottom: 0;
    img {
      padding-right: 8px;
    }
  }
}
