.auth-page {
  height: 100%;
  min-height: 100%;
  background: linear-gradient(90deg, #004A99 0%, #003E80 100%);
  padding: ($grid-size * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  a {
    color: $white;
  }

  .content {
    text-align: center;
    width: 100%;
    max-width: 600px;

    .logo-large {
      margin-bottom: 50px;
    }

    .password-form {
      max-width: 382px;
      margin: auto;
      .auth-submit {
        width: 100%;
      }
    }

    .forgot-password {
      position: absolute;
      right: 0;
      bottom: 75px;
    }

    .auth-submit {
      display: inline-block;
      margin: 25px auto 0;
    }

    .actions {
      padding: 1px;
    }
    &.terms-content {
      max-width: 902px;
      padding: $grid-size;
    }
  }
}
