#user-page {
  .back-button {
    margin-top: ($grid-size * 2);
  }

  .user-content {
    display: flex;
    width: 100%;
  }
}

#invite-to-site-form {
  .heading-label {
    padding-top: ($grid-size * 2);
    &:first-of-type {
      padding-top: $grid-size;
    }
  }
}
