.onboarding-slide {
  display: flex;
  flex-flow: column;
  align-items: center;
  img {
    height: $grid-size * 24;
    width: $grid-size * 24;
  }

  h2 {
    margin-top: $grid-size * 4;
    margin-bottom: $grid-size * 2;
    color: $heading-blue;
    font-family: 'Calibri', sans-serif;
    line-height: 28px;
  }

  .onboarding-text {
    width: 400px;
    height: 105px;
    font-size: 16px;
    line-height: 26px;
    color: $brown;
    text-align: center;

    &.wide {
      width: 450px;
    }

    &.extra-wide {
      width: 580px;
    }

  }

  .skip-action {
    margin-top: $grid-size * 5;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button {
    font-size: $font-size-body;
    &.get-started {
      padding: 0;
      margin-top: $grid-size * 2.5;
      font-size: $font-size-body;
      height: 50px;
      width: 380px;
    }

    &.skip {
      margin-top: $grid-size * 3;
      text-transform: uppercase;
      color: $table-header-light-grey;
    }
  }
}
