.compliance-page {
    min-height: 100%;
    background: white;
    padding: ($grid-size * 2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $white;
    a {
      color: $white;
    }
  
    .left-panel {
        width: 308px;
        box-shadow: 1px 1px 5px 1px #D8D8D8;
        padding: 10px 20px;

        .general {
            height: 38px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid #EFEEEE;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px 0;

            .label {
                font-size: 14px;
                font-weight: 500;
                color: black;
            }

            .hyphen {
                font-size: 14px;
                font-weight: 300;
                color: black;
            }

            .text {
                font-size: 14px;
                font-weight: 500;
                color: #004289;
            }
        }

        .states-container {
            position: relative;
            margin-top: 20px;
            padding: 20px 10px;
            background-color: #004289;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));;
            gap: 10px;

            .state {
                background-color: #EFEFEF;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;

                &:hover {
                    cursor: pointer;
                }

                span {
                    font-size: 14px;
                    font-weight: 300;
                    color: black;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .active {
                background-color: #7CA1C7;

                span {
                    color: white;
                }
            }

            .show-all {
                grid-column: span 3;
            }
        }

        .diff-sites {
            position: relative;
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between !important;

            span {
                font-size: 14px;
                color: #C22007;
                cursor: pointer;
            }

            .error-message {
                top: 25px;
                position: absolute;
                width: 250px;
                padding: 5px 20px;
                background-color: white;
                box-shadow: 1px 1px 5px 1px darkgrey;

                p {
                    font-size: 13px;
                    color: #C22007;
                }
            }
        }

        .sites-overdue {
            margin-top: 20px;
            width: 100%;

            .title-container {
                height: 41px;
                width: 100%;
                background-color: #004289;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 18px;
                    color: white;

                    .underline {
                        text-decoration: underline;
                    }
                }
            }

            .content {
                background-color: #D8D8D8;
                border: 1px solid #979797;
                border-top: none;
                border-bottom-left-radius: 10px;
                padding-left: 10px;

                .row {
                    display: flex;
                    width: 100%;
                    border-bottom: 1px solid #C1C1C1;

                    p {
                        margin-left: 15px;
                        font-size: 12px;
                        color: black;
                        text-decoration: underline;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .row:last-of-type {
                    border-bottom: none;
                }

                .center {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 15px 10px 15px 0;

                    .all-states {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 24px;
                        width: 210px;
                        background-color: #fff;
                        border-radius: 6px;

                        .label {
                            font-size: 12px;
                            font-weight: 500;
                            color: black;
                        }
            
                        .hyphen {
                            font-size: 12px;
                            font-weight: 300;
                            color: black;
                        }
            
                        .text {
                            font-size: 12px;
                            font-weight: 500;
                            color: #C22007;
                        }
                    }

                    .export-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-top: 10px;
                        margin-right: 10px;

                        .export {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 24px;
                            width: 100px;
                            background-color: #fff;
                            border-radius: 6px;
                            margin-left: 10px;
    
                            img {
                                height: 10px;
                                width: 10px;
                                object-fit: contain;
                                margin-right: 5px;
                            }
    
                            span {
                                font-size: 12px;
                                font-weight: 500;
                                color: #004289;
                            }
    
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .items {
                    height: 400px;
                    overflow-y: scroll;
                }

                .items::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                
                .items::-webkit-scrollbar:vertical {
                    width: 11px;
                }

                .items::-webkit-scrollbar-thumb {
                    border-radius: 0;
                    border: 1px solid #979797; 
                    background-color: #979797;
                }

                .items::-webkit-scrollbar-track {
                    border-radius: 0;
                    border-left: 1px solid #979797; 
                    background-color: #C1C1C1;
                }
            }
        }

        .logbooks-overdue {
            margin-top: 20px;
            width: 100%;

            .title-container {
                height: 41px;
                width: 100%;
                background-color: #004289;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 18px;
                    color: white;

                    .underline {
                        text-decoration: underline;
                    }
                }
            }

            .content {
                background-color: #D8D8D8;
                border: 1px solid #979797;
                border-top: none;
                border-bottom-left-radius: 10px;
                padding-left: 10px;

                .row {
                    display: flex;
                    width: 100%;
                    border-bottom: 1px solid #C1C1C1;

                    p {
                        margin-left: 15px;
                        font-size: 12px;
                        color: black;
                    }
                }

                .row:last-of-type {
                    border-bottom: none;
                }

                .center {
                    justify-content: center;
                    align-items: center;
                    padding: 15px 10px 15px 0;

                    .all-states {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 24px;
                        width: 173px;
                        background-color: #fff;
                        border-radius: 6px;

                        .label {
                            font-size: 12px;
                            font-weight: 500;
                            color: black;
                        }
            
                        .hyphen {
                            font-size: 12px;
                            font-weight: 300;
                            color: black;
                        }
            
                        .text {
                            font-size: 12px;
                            font-weight: 500;
                            color: #C22007;
                        }
                    }
                }

                .items {
                    height: 280px;
                    overflow-y: scroll;
                }

                .items::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                
                .items::-webkit-scrollbar:vertical {
                    width: 11px;
                }

                .items::-webkit-scrollbar-thumb {
                    border-radius: 0;
                    border: 1px solid #979797; 
                    background-color: #979797;
                }

                .items::-webkit-scrollbar-track {
                    border-radius: 0;
                    border-left: 1px solid #979797; 
                    background-color: #C1C1C1;
                }
            }
        }
    }

    .right-section {
        position: relative;
        width: 864px;
        margin-left: 10px;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .loading-icon {
            position: absolute;
            height: 50px;
            width: 50px;
            top: 20px;
            right: 10px;
        }

        .loading-text {
            position: absolute;
            height: 50px;
            width: 50px;
            top: 65px;
            right: 6px;
            color: #004289;
            font-size: 12px;
        }

        .dashboard-title {
            font-size: 28px;
            font-weight: 400;
            color: black;
            margin-bottom: 40px;
        }

        .tab-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            .tab {
                height: 48px;
                width: 263px;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                background-color: #7CA1C7;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 20px;

                span {
                    font-size: 18px;
                    color: #004792;
                    font-weight: 500;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .active {
                background-color: #004792;

                span {
                    color: white;
                }
            }
        }

        .tab-content {
            width: 100%;
            box-shadow: 1px 1px 5px 1px #D8D8D8;
            padding: 20px;
            display: none;

            .summary {
                width: 100%;
                border: 1px solid #979797;
                border-radius: 21px;

                .title-container {
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #004792;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;

                    span {
                        font-size: 18px;
                        color: white;
                        font-weight: 500;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: row;
                    background-color: #D8D8D8;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding: 0 20px;

                    .column {
                        display: flex;
                        flex-direction: column;
                        flex: 2;

                        .item {
                            transition: all 1s ease;
                            border-top: 1px solid #C1C1C1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            span {
                                font-size: 14px;
                                line-height: 18px;
                                color: black;
                                text-align: center;
                                margin: 8px 0;
                            }

                            strong {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 18px;
                                color: black;
                                text-align: center;
                                margin: 8px 0;
                            }

                            .red {
                                background-color: #C22007;
                                width: 80px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                
                                span, strong {
                                    color: white;
                                }
                            }

                            .orange {
                                background-color: #eb9d38;
                                width: 80px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                
                            }

                            .tooltip {
                                top: 47px;
                                position: absolute;
                                z-index: 1;
                                width: 240px;
                                padding: 5px 11px;
                                border-radius: 3px;
                                background-color: white;
                                box-shadow: 1px 1px 5px 1px darkgrey;

                                p {
                                    font-size: 14px;
                                    color: #004289;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .extra-width {
                        flex: 3;
                    }
                }
            }

            .mid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 12px;

                .fittings-installed-panel {
                    width: 48%;
                    border: 1px solid #979797;
                    border-radius: 21px;

                    .title-container {
                        height: 48px;
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #004792;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
    
                        h1 {
                            font-size: 18px;
                            line-height: 20px;
                            color: white;
                            font-weight: 500;
                            margin: 0;
                        }

                        h2 {
                            font-size: 12px;
                            line-height: 14px;
                            color: white;
                            font-weight: 200;
                            margin: 0;
                        }

                        .tooltip {
                            top: 43px;
                            position: absolute;
                            z-index: 1;
                            width: 300px;
                            padding: 5px 11px;
                            border-radius: 3px;
                            background-color: white;
                            box-shadow: 1px 1px 5px 1px darkgrey;

                            p {
                                font-size: 14px;
                                color: #004289;
                                text-align: center;
                            }
                        }
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        padding: 20px;

                        .tabs {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 173px;
                            
                            .tab {
                                border-top-left-radius: 6px;
                                border-top-right-radius: 6px;
                                height: 23px;
                                width: 86px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: white;
                                border: 1px solid #004289;
                                border-bottom: none;
                                &:hover {
                                    cursor: pointer;
                                }
    
                                span {
                                    font-size: 12px;
                                    font-weight: 300;
                                    color: #004289;
                                }
                            }

                            .active {
                                background-color: #004289;

                                span {
                                    color: white;
                                }
                            }
                        }

                        

                        .all-states {
                            border-radius: 6px;
                            height: 24px;
                            width: 173px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #439D26;

                            span {
                                font-size: 12px;
                                font-weight: 300;
                                color: white;
                            }
                        }

                        p {
                            color: black;
                            font-size: 9px;
                            font-style: italic;
                            margin: 0;
                        }

                        .y-label {
                            position: absolute;
                            left: -12px;
                            top: 110px;
                            transform: rotate(-90deg);
                        }
                    }
                }

                .upcoming-tests-panel {
                    width: 48%;
                    border: 1px solid #979797;
                    border-radius: 21px;

                    .title-container {
                        height: 48px;
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #004792;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
    
                        h1 {
                            font-size: 18px;
                            line-height: 20px;
                            color: white;
                            font-weight: 500;
                            margin: 0;
                        }

                        h2 {
                            font-size: 12px;
                            line-height: 14px;
                            color: white;
                            font-weight: 200;
                            margin: 0;
                        }

                        .tooltip {
                            top: 38px;
                            position: absolute;
                            z-index: 1;
                            width: 300px;
                            padding: 5px 11px;
                            border-radius: 3px;
                            background-color: white;
                            box-shadow: 1px 1px 5px 1px darkgrey;

                            p {
                                font-size: 14px;
                                color: #004289;
                                text-align: center;
                            }
                        }
                    }

                    .content {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-bottom-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        padding: 20px;

                        .all-states {
                            border-radius: 6px;
                            height: 24px;
                            width: 173px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #C22007;

                            span {
                                font-size: 12px;
                                font-weight: 300;
                                color: white;
                            }
                        }

                        p {
                            color: black;
                            font-size: 9px;
                            font-style: italic;
                            margin: 0;
                        }

                        .y-label {
                            position: absolute;
                            left: -12px;
                            top: 110px;
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            .gauge-container {
                position: relative;
                height: 210px;
                width: 100%;
                border-radius: 21px;
                border: 1px solid #979797;
                margin-top: 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 25px;

                .title-container {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #004792;
                    border-bottom-left-radius: 20px;
                    border-top-right-radius: 20px;
                    padding: 0 80px;

                    span {
                        font-size: 18px;
                        color: white;
                        font-weight: 500;
                    }

                    .tooltip {
                        top: 38px;
                        position: absolute;
                        z-index: 1;
                        width: 400px;
                        padding: 5px 11px;
                        border-radius: 3px;
                        background-color: white;
                        box-shadow: 1px 1px 5px 1px darkgrey;

                        p {
                            font-size: 14px;
                            color: #004289;
                            text-align: center;
                        }
                    }
                }

                .overall-gauge table tbody tr td div div div svg g g text {
                    color: red;
                }

                .state-gauges {
                    margin: 40px 0 0 20px;
                }
            }

            .upcoming-tests {
                width: 100%;
                border: 1px solid #979797;
                border-radius: 21px;

                .title-container {
                    height: 48px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: #004792;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;

                    h1 {
                        font-size: 18px;
                        line-height: 20px;
                        color: white;
                        font-weight: 500;
                        margin: 0;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: row;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    background-color: #D8D8D8;
                    padding: 0 40px 40px 40px;
                    min-height: 700px;

                    .column {
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        .item {
                            border-top: 1px solid #C1C1C1;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            span, a {
                                font-size: 14px;
                                line-height: 18px;
                                color: black;
                                text-align: center;
                                margin: 8px 0;
                            }

                            strong {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 18px;
                                color: black;
                                text-align: center;
                                margin: 8px 0;
                            }

                            a {
                                text-decoration: underline;
                            }
                        }

                        .first {
                            padding: 5px 0;
                        }

                        .start {
                            justify-content: start;
                            padding-left: 50px;
                        }
                    }

                    .first-column {
                        flex: 2;
                    }
                }
            }
        }
    }
  }
  