#file-upload-error-modal .modal-content {
  font-size: $font-size-body;

  > .inner {
    max-height: 215px;
    overflow-y: scroll;
    text-align: left;

    .error-message {
      font-weight: $font-weight-medium;
    }

    .errors-list {
      color: $red;

      .error {
        line-height: 23px;
      }
    }
  }
  .template-download {
    margin-top: ($grid-size * 2);
    .green {
      color: $green;
    }
  }
}
