#invite {
  .title {
    display: flex;
    justify-content: center;
    margin-bottom: ($grid-size * 3);

    .invite-icon {
      margin-right: ($grid-size * 2);
    }
  }

  .actions {
    .auth-submit {
      display: block;
      :last-of-type {
        padding-top: ($grid-size / 2);
      }
    }
  }
}
