.validated-multi-select {

  .arrow-up {
    position: absolute;
    right: $grid-size;
    top: $grid-size;
    cursor: pointer;
  }
  .selection {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;

    .selected-item + * {
      margin-right: 5px;
    }

    .selected-item {
      margin: 0 5px 5px 0;

      // Fixes bug where "All Groups" button doesn't render properly in Safari
      -webkit-transform-style: preserve-3d;

      .icon-close {
        margin-left: 5px;
      }
    }
  }

  select {
    background-image: none !important;
  }

  &:not(.active) {
    background-image: url("../../images/icon-arrow-down.svg");
    background-position: right 10px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 50px;

    select, .multi-select {
      height: 0;
    }
  }
}
