.modal-success {
  text-align: center;

  .success-text {
    margin: 17px auto 45px;
  }

  .modal-success-action {
    .button {
      margin: auto;
    }
  }

  .modal-success-action {
    .button {
      margin: auto;
    }
  }
}
