.main-page {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;

    .page-header-actions {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
  }
}
