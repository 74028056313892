$font-weight-ultralight: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-heavy: 700;

$font-size-h1: 26px;
$font-size-h2: 24px;
$font-size-h3: 22px;
$font-size-h4: 20px;
$font-size-subheading: 18px;
$font-size-body: 16px;
$font-size-tooltip: 14px;
$font-size-table: 14px;
$font-size-label: 14px;
$font-size-form-label: 12px;
$font-size-footnote: 12px;
$font-size-table-heading: 12px;
$font-size-small: 11px;

$line-height-h1: 28px;
$line-height-h2: 24px;
$line-height-h3: 22px;
$line-height-h4: 24px;
$line-height-subheading: 22px;
$line-height-body: 16px;
$line-height-label: 14px;
$line-height-footnote: 12px;
$line-height-table-heading: 15px;

$grid-size: 10px;
$max-width: 1300px;

$white: #fff;
$blue: #004a99;
$pale-blue: #7CA1C7;
$green: #95c11f;
$red: #ba1312;
$error-red: #f54a49;
$black: #201c1d;
$brown: #666162;
$grey: #85A0BF;
$grey-border: #CDCDCD;
$light-grey: #EDEDED;
$light-grey-background: #FAFAFA;
$light-blue: #EDF3FA;
$table-header-light-grey: #A0A0A0;
$disabled-grey-label: #D0D0D0;
$disabled-grey-value: #C2C2C2;
$row-hover-blue: #EEF4FA;
$blue-gradient: linear-gradient(270deg, $blue 0%, #003E80 100%);
$heading-blue: #053687;

$table-box-shadow: 0 0 2px 1px rgba(0,0,0,0.07), 0 3px 6px 0 rgba(0,0,0,0.07);
$table-row-box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.07);
$form-row-box-shadow: 0 0 1px 1px rgba(0,0,0,0.06), 0 1px 2px 0 rgba(0,0,0,0.07);
$form-row-box-shadow-focused: 0 0 1px 1px rgba(0,0,0,0.15), 0 1px 2px 0 rgba(0,0,0,0.15);

// Component specific

$nav-bar-height: 60px;
