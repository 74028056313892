#fault-table {
  .select-all-row {
    .select-all-column {
      &.replace-all-fittings {
        min-width: 120px;
      }
      &.replace-all-components {
        min-width: 250px;
      }
      &.replace-all-decals {
        min-width: 110px;
      }
    }

  }

  tbody {
    tr.order-replacement-row {
      &:hover {
        background: none;
        cursor: default;
        color: $brown;
        font-weight: $font-weight-regular;
      }
    }
  }

  .column {
    &.device-reference {
      width: 40%;
    }
    &.replacement-fitting-option {
      min-width: 120px;
      justify-content: center;
    }
    &.replacement-faulty-component-option {
      min-width: 250px;
      justify-content: center;
    }
    &.replacement-decal-option {
      min-width: 110px;
      justify-content: center;
    }
    &.row-indicator {
      width: 6px;
    }
  }

}
