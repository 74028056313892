#test-event-table {
  .row {
    .icon-test-event-blue {
      display: none
    }

    .icon-test-event {
      margin: 0 $grid-size 2px;
      flex-shrink: 0;
    }

    &:hover {
      .icon-test-event-grey {
        display: none;
      }
      .icon-test-event-blue {
        display: inline-block;
      }
    }

    td:last-of-type {
      padding: 0px;
    }
  }

  .column {
    &.startDate {
      width: 25%;
    }
    &.endDate {
      width: 25%;
    }
  }

  td.column.startDate {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;

    &.checkbox {
      padding: 9px 0;
    }
  }

  .test-event-archived {
    font-size: $font-size-table-heading;
    font-weight: $font-weight-medium;
    line-height: $line-height-table-heading;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-right: $grid-size;
    color: $table-header-light-grey;
  }

  .cancel {
    min-width: 140px;
  }
}
