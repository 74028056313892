.internal-auth-form.auth-form.form {
  max-width: 382px;
  margin: 45px auto 0;

  .row {
    input, select, .label {
      color: $black;
    }
    .input {
      border: none;
      box-shadow: none;
      border-radius: 0;

      input {
        background-image: linear-gradient(to bottom, silver, silver), linear-gradient(to bottom, silver, silver);
        &:focus {
          background-image: linear-gradient(to bottom, silver, silver), linear-gradient(to bottom, $black, $black);
        }
        &.error {
          background-image: linear-gradient(to bottom, $error-red, $error-red), linear-gradient(to bottom, silver, silver);
          background-size: 100% 2px, 100% 1px;
        }

        &.valid {
          background-image: linear-gradient(to bottom, $green, $green), linear-gradient(to bottom, silver, silver);
          background-size: 100% 2px, 100% 1px;
        }
      }
      select {
        background-image: linear-gradient(to bottom, silver, silver), linear-gradient(to bottom, silver, silver), url("../../images/icon-arrow-down.svg");
        background-size: 0 2px, 100% 1px, 30px;
        background-position: 0% 100%, 0% 100%, right center;
        &:focus {
          background-image: linear-gradient(to bottom, silver, silver), linear-gradient(to bottom, $black, $black), url("../../images/icon-arrow-down.svg");
        }
        &.error {
          background-image: linear-gradient(to bottom, $error-red, $error-red), linear-gradient(to bottom, silver, silver), url("../../images/icon-arrow-down.svg");
          background-size: 100% 2px, 100% 1px, 30px;;
        }

        &.valid {
          background-image: linear-gradient(to bottom, $green, $green), linear-gradient(to bottom, silver, silver), url("../../images/icon-arrow-down.svg");
          background-size: 100% 2px, 100% 1px, 30px;;
        }
      }
      &.focused .label-animated, .password-toggle {
        opacity: 1;
      }
    }
  }
  .business-confirmation {
    input[type="checkbox"] + label::before {
      content: url(../../icons/checkbox-unchecked.svg);
    }
  }
  .submit {
    margin-top: 70px;
    width: 100%;
  }
}
